import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Про Нас |  Форсаж - Кращі курси водіння
			</title>
			<meta name={"description"} content={"Курси водіння Форсаж - професійне навчання водінню для новачків і досвідчених водіїв. Поліпшіть свої навички з нами."} />
			<meta property={"og:title"} content={"Про Нас |  Форсаж - Кращі курси водіння"} />
			<meta property={"og:description"} content={"Курси водіння Форсаж - професійне навчання водінню для новачків і досвідчених водіїв. Поліпшіть свої навички з нами."} />
			<meta property={"og:image"} content={"https://barinastorin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://barinastorin.com/img/4357863.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://barinastorin.com/img/4357863.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://barinastorin.com/img/4357863.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://barinastorin.com/img/4357863.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://barinastorin.com/img/4357863.png"} />
			<meta name={"msapplication-TileImage"} content={"https://barinastorin.com/img/4357863.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші послуги
				</Text>
				<Text font="--lead" color="--darkL2" padding="0px 0px 50px 0px">
				У школі водіння Форсаж ми пропонуємо різноманітні курси водіння для задоволення потреб кожного студента. Наші програми розроблені для різних рівнів володіння навичками водіння та орієнтовані на досягнення конкретних цілей. Давайте розглянемо детальніше наші послуги.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Курси для початківців
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Курси для початківців призначені для тих, хто тільки починає вивчати водіння. Ми допоможемо вам освоїти основи керування автомобілем, правила дорожнього руху та розвитку безпечних навичок водіння. Ви навчитеся базовим технікам, які допоможуть вам стати впевненим водієм.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://barinastorin.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://barinastorin.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Курси для досвідчених водіїв
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Курси для досвідчених водіїв спрямовані на тих, хто вже має певний досвід водіння і хоче вдосконалити свої навички. Ми зосередимося на розширенні вашого знання про техніку водіння, навчанні новим прийомам та технікам. Наші інструктори допоможуть вам вільно керувати автомобілем у різних умовах.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Курси для водіїв-професіоналів
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Курси для водіїв-професіоналів призначені для тих, хто працює в сфері перевезень та потребує підвищення кваліфікації. Ми пропонуємо програми, що включають вивчення техніки водіння важких транспортних засобів, розвиток навичок економного водіння та підготовку до роботи в екстремальних умовах.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://barinastorin.com/img/8.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://barinastorin.com/img/9.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Курси екстремального водіння
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Курси екстремального водіння призначені для тих, хто хоче навчитися керувати автомобілем у складних умовах. Ми навчимо вас технікам безпечного водіння на слизьких дорогах, в умовах поганої видимості та інших екстремальних ситуаціях. Наші інструктори допоможуть вам набути впевненості за кермом та підвищити рівень безпеки на дорозі.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="45%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Запишіться на наші курси вже сьогодні!
				</Text>
			</Box>
			<Box
				width="55%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				Обирайте курс, який відповідає вашим потребам, і почніть покращувати свої навички водіння з школою водіння Форсаж. Наші інструктори допоможуть вам досягти успіху.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});